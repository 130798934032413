import React, {useState} from "react";
import {useSelector} from "react-redux";

import {IState} from "../../types/state";
import {postUrlWithStore} from "./FooterNewsLetter/postUrlWithStore";
import FooterNewsLetterJSX from "./FooterNewsLetter";

export default function FooterNewsletter() {
    const [email, SetEmail] = useState<string>("");
    const [success, SetSuccess] = useState<{ class: string, message: string }>();

    const handleChange = (event: { target: { value: string } }) => {
        SetEmail(event.target.value);
    };

    const handlerClick = (event: { preventDefault: () => void }) => {
        event.preventDefault();
        SetSuccess(postUrlWithStore(email));
    };
    return (
        <>
                <FooterNewsLetterJSX
                    handlerClick={handlerClick}
                    handleChange={handleChange}
                    success={success}
                    email={email}
                />
        </>
    );
}
