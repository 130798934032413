import React, {FC} from "react"
import {FormattedMessage} from "react-intl";
import {CustomTextField} from "../../../atoms/CustomTextField";
import {IFooterNLJSX as IProps} from "./types";
import {genKey} from "../../../services/utils";

const FooterNewsLetterJSX: FC<IProps> = ({
                                             handleChange,
                                             email,
                                             success,
                                             handlerClick
                                         }) => (
    <div className="footer-newsletter-container">
        <h3 className="footer-newsletter__title">
            <FormattedMessage id="subscribe" defaultMessage="Subscribe"/>
        </h3>

        <form action="" className="footer-newsletter__form">

            <CustomTextField
                handleChange={handleChange}
                inpValue={email}
                label="E-mail"
                value="email"
                name="email"
                type="email"
            />
            {
                success && (
                    <small id="passwordHelp" className={success?.class}>
                        <FormattedMessage
                            id={genKey(success.message)}
                            defaultMessage={success.message}
                        />
                    </small>
                )
            }
            <div className="btn-wrapper">
                <button
                    onClick={handlerClick}
                    type="submit"
                    className="footer-newsletter__form-button btn "
                >
                    <FormattedMessage
                        id="subscribe_button"
                        defaultMessage="Subscribe"
                    />
                </button>
            </div>
        </form>
    </div>
)

export default FooterNewsLetterJSX;
